import React, { useEffect, useState } from 'react';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { Loader } from '../Loader/Loader';
interface MapWrapperProps {
  children?: React.ReactNode;
}

export const MapWrapper: React.FC<MapWrapperProps> = (props) => {
  const { children } = props;

  const [status, setStatus] = useState<Status>();
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (status !== Status.SUCCESS) return;
    setTimeout(() => setShowLoading(false), 250);
  }, [status]);

  const render = (status: Status): React.ReactElement => {
    if (status === Status.FAILURE) return <div>Error</div>;
    return <Loader />;
  };

  return (
    <>
      <Loader show={showLoading} />
      <Wrapper apiKey="AIzaSyAmu6Qb1yNoehPfvJdx0QUxM2nE8X4L1q8" render={render} callback={setStatus}>
        {children}
      </Wrapper>
    </>
  );
};
