export default {
  appLanguage: 'CS',

  Sidebar: {
    audio: 'Audio',
    photos: 'Photos',
    pointOfInterests: 'Points of interests',
  },

  SidebarFilters: {
    all: 'All',
    themes: 'Themes',
    periods: 'Periods',
    apply: 'Apply filters',
    reset: 'Reset',
  },
};
