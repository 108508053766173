import { PropsWithChildren, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Point, TSFixMe } from '../../../types';

const createOverlay = (
  container: HTMLElement,
  pane: keyof google.maps.MapPanes,
  position: google.maps.LatLng | google.maps.LatLngLiteral
) => {
  class Overlay extends google.maps.OverlayView {
    container: HTMLElement;
    pane: keyof google.maps.MapPanes;
    position: google.maps.LatLng | google.maps.LatLngLiteral;

    constructor(
      container: HTMLElement,
      pane: keyof google.maps.MapPanes,
      position: google.maps.LatLng | google.maps.LatLngLiteral
    ) {
      super();
      this.container = container;
      this.pane = pane;
      this.position = position;
    }

    onAdd(): void {
      const pane = this.getPanes()?.[this.pane];
      pane?.appendChild(this.container);
    }

    draw(): void {
      const projection = this.getProjection();
      const point = projection.fromLatLngToDivPixel(this.position);

      if (point === null) return;

      this.container.style.transform = `translate(${point.x}px, ${point.y}px)`;
    }

    onRemove(): void {
      if (this.container.parentNode !== null) {
        this.container.parentNode.removeChild(this.container);
      }
    }
  }
  return new Overlay(container, pane, position);
};

type OverlayProps = PropsWithChildren<{
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  pane?: keyof google.maps.MapPanes;
  map?: google.maps.Map;
  onClick?: (e: MouseEvent) => void;
}>;

export default function CustomPoint(props: OverlayProps) {
  const { position, pane = 'floatPane', map, children, onClick } = props;

  const container = useMemo(() => {
    const div = document.createElement('div');

    div.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      if (onClick) onClick(e);
    });

    div.style.position = 'absolute';
    return div;
  }, [onClick]);

  const overlay = useMemo(() => {
    return createOverlay(container, pane, position);
  }, [container, pane, position]);

  useEffect(() => {
    if (!map) return;

    overlay?.setMap(map);
    return () => overlay?.setMap(null);
  }, [map, overlay]);

  return createPortal(children, container);
}

interface CustomMarkerProps {
  point: Point;

  onClick?: (e: TSFixMe, point: Point) => void;
  map?: google.maps.Map;
  isSelected?: boolean;
}

export const MarkerWrapper: React.FC<CustomMarkerProps> = (props) => {
  const { onClick, map = undefined, isSelected = false, point } = props;

  return (
    <CustomPoint position={point.coordinate} onClick={onClick ? (e) => onClick(e, point) : undefined} map={map}>
      <div className={`MapMarker${isSelected ? ' MapMarker--selected' : ''}`}>
        <svg width="55" height="73" viewBox="0 0 55 73" fill="none" xmlns="http://www.w3.org/2000/svg">
          {isSelected && (
            <path
              opacity="0.347"
              d="M54.356 27.178C54.356 39.55 37.794 61.578 30.533 70.663C30.1326 71.1694 29.6228 71.5786 29.0418 71.8599C28.4607 72.1412 27.8236 72.2873 27.178 72.2873C26.5324 72.2873 25.8953 72.1412 25.3142 71.8599C24.7332 71.5786 24.2234 71.1694 23.823 70.663C16.434 61.576 0 39.55 0 27.178C0 19.9699 2.86339 13.0571 7.96025 7.96025C13.0571 2.86339 19.9699 0 27.178 0C34.3861 0 41.2989 2.86339 46.3957 7.96025C51.4926 13.0571 54.356 19.9699 54.356 27.178V27.178ZM27.178 2.265C20.5707 2.26527 14.2342 4.89011 9.56214 9.56214C4.89011 14.2342 2.26527 20.5707 2.265 27.178C2.47221 30.5924 3.32815 33.936 4.787 37.03C6.59427 41.1605 8.70922 45.1493 11.114 48.963C15.5732 55.9793 20.4065 62.7508 25.593 69.248C25.7808 69.4894 26.0213 69.6847 26.2961 69.819C26.5708 69.9534 26.8726 70.0232 27.1785 70.0232C27.4843 70.0232 27.7862 69.9534 28.0609 69.819C28.3357 69.6847 28.5762 69.4894 28.764 69.248C33.95 62.7502 38.7836 55.9788 43.244 48.963C45.6464 45.1478 47.7616 41.1591 49.572 37.03C51.0271 33.9348 51.8822 30.5918 52.092 27.178C52.0917 20.5706 49.4668 14.2339 44.7945 9.56179C40.1223 4.88972 33.7854 2.265 27.178 2.265V2.265Z"
              fill="#F5493A"
            />
          )}
          <path
            d="M24.482 62.5081C18.543 55.2041 5.33398 37.5021 5.33398 27.5581C5.33398 21.7647 7.6354 16.2086 11.7319 12.1121C15.8285 8.01553 21.3846 5.71411 27.178 5.71411C32.9714 5.71411 38.5275 8.01553 42.624 12.1121C46.7206 16.2086 49.022 21.7647 49.022 27.5581C49.022 37.5021 35.711 55.2041 29.874 62.5081C29.5522 62.9152 29.1425 63.2442 28.6755 63.4703C28.2085 63.6965 27.6964 63.8139 27.1775 63.8139C26.6586 63.8139 26.1465 63.6965 25.6795 63.4703C25.2125 63.2442 24.8027 62.9152 24.481 62.5081H24.482Z"
            fill="#F5493A"
          />
          <circle fill="#FFFFFF" cx="27" cy="28" r="6" />
        </svg>
      </div>
    </CustomPoint>
  );
};
