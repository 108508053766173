import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Points from './Points/Points';
import StaticRoutes from './StaticRoutes/StaticRoutes';

const App: React.FC = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Points />} />
        <Route path="/static-routes" element={<StaticRoutes />} />
        <Route path="/en" element={<Points />} />
        <Route path="/en/static-routes" element={<StaticRoutes />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
