import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from './common/i18n';

import { store } from './common/store/index';

import './assets/scss/app.scss';
import App from './App';

if (process.env.REACT_APP_VERSION) console.log(`App version: ${process.env.REACT_APP_VERSION}`);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);
