import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Period, Point, Topic } from '../types';
import { api } from '../services/apiSlice';
import { RootState } from './index';

export interface PointState {
  selectedPoint?: Point;
  points: Point[];
  periods: Period[];
  topics: Topic[];
}

const initialState: PointState = {
  selectedPoint: undefined,
  points: [],
  periods: [],
  topics: [],
};

const extendedApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getPoints: builder.query<Point[], void>({
      query: () => `/public/place/point-of-interest/index`,
      keepUnusedDataFor: 1,
      transformResponse: (response: { data: Point[] }) => {
        response.data.forEach((point) => {
          point.coordinate.lat = point.coordinate.latitude;
          point.coordinate.lng = point.coordinate.longitude;
        });

        return response.data;
      },
    }),
  }),
});

export const { useGetPointsQuery } = extendedApi;

const pointSlice = createSlice({
  name: 'point',
  initialState,
  reducers: {
    setSelectedPoint(state, action: PayloadAction<Point | undefined>) {
      state.selectedPoint = action.payload;
    },
    setFilterTopics(state, action: PayloadAction<string | undefined>) {
      const topic = state.topics.find((p) => p.id === action.payload);
      if (topic) topic.isSelected = !topic.isSelected;
    },
    setFilterPeriods(state, action: PayloadAction<string | undefined>) {
      const period = state.periods.find((p) => p.id === action.payload);
      if (period) period.isSelected = !period.isSelected;
    },
    setFilters(state, action: PayloadAction<undefined>) {
      const filteredTopics = state.topics.filter((t) => t.isSelected).map((t) => t.id);
      const filteredPeriods = state.periods.filter((p) => p.isSelected).map((p) => p.id);
      const hasNoFilters = filteredPeriods.length === 0 && filteredTopics.length === 0;

      state.points.forEach((point) => {
        point.isHidden = false;
        if (hasNoFilters) return;

        let isInPeriods = filteredPeriods.length > 0 ? false : true;
        if (filteredPeriods.length > 0 && filteredPeriods.includes(point.period.id)) {
          isInPeriods = true;
        }

        let isInTopics = filteredTopics.length > 0 ? false : true;
        if (isInPeriods && filteredTopics.length > 0) {
          point.topics.forEach((t) => {
            if (isInTopics) return;
            if (filteredTopics.includes(t.id)) isInTopics = true;
          });
        }

        point.isHidden = !(isInPeriods && isInTopics);
      });
    },
    resetFilters(state) {
      state.topics.forEach((t) => (t.isSelected = false));
      state.periods.forEach((p) => (p.isSelected = false));

      state.points.forEach((p) => (p.isHidden = false));
    },
  },
  extraReducers(builder) {
    builder.addMatcher(extendedApi.endpoints.getPoints.matchFulfilled, (state, { payload }) => {
      const periods: Period[] = [];
      const topics: Topic[] = [];

      payload.forEach((point) => {
        if (!periods.find((p) => p.id === point.period.id)) periods.push(point.period);

        point.topics.forEach((topic) => {
          if (!topics.find((t) => t.id === topic.id)) topics.push(topic);
        });
      });

      periods.sort((a, b) => a.name.localeCompare(b.name));
      topics.sort((a, b) => a.name.localeCompare(b.name));

      state.points = payload;
      state.periods = periods;
      state.topics = topics;
    });
  },
});

export const { setSelectedPoint, setFilterPeriods, setFilterTopics, setFilters, resetFilters } = pointSlice.actions;
export const selectPoint = (state: RootState): PointState => state.point;
export default pointSlice.reducer;
