import { Point, TSFixMe } from '../../../types';

export const routeTemps: TSFixMe[] = [];

export const getRoute = (points: Point[], bounds: google.maps.LatLngBounds): Promise<google.maps.Polyline> => {
  return new Promise((resolve, reject) => {
    const directionsService = new google.maps.DirectionsService();
    const waypoints: TSFixMe[] = [];

    points.forEach((point) => {
      const stop = new google.maps.LatLng(point.coordinate.latitude, point.coordinate.longitude);

      waypoints.push({ location: stop, stopover: true });
      bounds.extend(point.coordinate);
    });

    const origin = new google.maps.LatLng(points[0].coordinate.latitude, points[0].coordinate.longitude);
    const destination = new google.maps.LatLng(
      points[points.length - 1].coordinate.latitude,
      points[points.length - 1].coordinate.longitude
    );

    const request = {
      origin: origin,
      destination: destination,
      waypoints: waypoints.slice(1, -1),
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.WALKING,
    };

    return directionsService.route(request, function (response, status) {
      if (status !== google.maps.DirectionsStatus.OK || !response?.routes?.[0]) return reject(new Error('API error'));

      const polyline = new google.maps.Polyline({
        path: response?.routes[0].overview_path,
        geodesic: true,
        strokeColor: '#f5493a',
        strokeOpacity: 1,
        strokeWeight: 4,
      });

      resolve(polyline);
    });
  });
};
