import { MarkerWrapper } from '../CustomPoint/CustomPoint';
import { Point, Route, TSFixMe } from '../../../types';

interface IgetPoints {
  points: Point[];
  route?: Route;
  selectedPoint?: Point;
  map: google.maps.Map;
  onMarkerClick?: (event: TSFixMe, point: Point) => void;
}

export const getPoints = ({ points, route, selectedPoint, map, onMarkerClick }: IgetPoints) => {
  const array: JSX.Element[] = [];

  points.forEach((point) => {
    const key = route?.id ? `${route.id}-${point.id}` : point.id;
    const isSelected = point.id === selectedPoint?.id;

    array.push(<MarkerWrapper key={key} isSelected={isSelected} point={point} onClick={onMarkerClick} map={map} />);
  });

  return array;
};
