import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../common/hooks/redux';
import { selectPoint, setSelectedPoint, useGetPointsQuery } from '../common/store/pointSlice';

import { AudioPlayer, SidebarPoint, Map, MapWrapper, Icon, Loader, SidebarFilters } from '../common/components';
import { setAudioFile, toggleSidebarFilter } from '../common/store/uiSlice';
import { Point } from '../common/types';
import { useTranslation } from 'react-i18next';
import { getLanguage, nativeNavigate } from '../common/utils/navigate';

const Points: React.FC = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(getLanguage());
  }, []);

  const dispatch = useAppDispatch();

  const { isLoading } = useGetPointsQuery();

  const selector = useAppSelector(selectPoint);
  const points = selector.points;
  const periods = selector.periods;
  const topics = selector.topics;

  const selectedPoint = selector.selectedPoint;

  const handleNavigate = () => {
    dispatch(setAudioFile(null));
    dispatch(setSelectedPoint());
    nativeNavigate('static-routes', getLanguage());
  };

  const handleClick = (point: Point | undefined, resetAudio: boolean) => {
    if (resetAudio) dispatch(setAudioFile(null));
    dispatch(setSelectedPoint(point));
  };

  if (isLoading) return <Loader show={isLoading} />;

  return (
    <>
      <MapWrapper>
        <Map
          points={points.filter((p) => !p.isHidden)}
          selectedPoint={selectedPoint}
          onMapClick={() => handleClick(undefined, false)}
          onMarkerClick={(e, point) => handleClick(point, true)}
          mapType="points"
        />
      </MapWrapper>

      <div onClick={handleNavigate} className="Toggler Toggler--navigation">
        <Icon name="navStaticRoutes" />
      </div>

      <div className="Toggler Toggler--filter" onClick={() => dispatch(toggleSidebarFilter())}>
        <Icon name="filter" />
      </div>
      <div
        className="Toggler Toggler--language"
        onClick={() => nativeNavigate('', getLanguage() === 'cs' ? 'en' : 'cs')}
      >
        {t('appLanguage')}
      </div>

      <SidebarFilters type="points" topics={topics} periods={periods} />
      <SidebarPoint selectedPoint={selectedPoint} />
      <AudioPlayer />
    </>
  );
};

export default Points;
