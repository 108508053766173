import React from 'react';
import cx from 'classnames';
import { Text } from '../Text/Text';

interface ButtonProps {
  title: string;
  variant?: 'primary' | 'secondary';
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const { variant = 'primary', title } = props;

  return (
    <button className={cx('Button', `Button--${variant}`)} onClick={props.onClick}>
      <Text weight={600} as="span">
        {title}
      </Text>
    </button>
  );
};
