import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../common/hooks/redux';
import { selectRoute, setSelectedPoint, setSelectedRoute, useGetRoutesQuery } from '../common/store/routeSlice';

import { AudioPlayer, SidebarPoint, Map, MapWrapper, Icon, Loader, SidebarFilters } from '../common/components';
import { setAudioFile, toggleSidebarFilter } from '../common/store/uiSlice';
import { useTranslation } from 'react-i18next';
import { getLanguage, nativeNavigate } from '../common/utils/navigate';

const StaticRoutes: React.FC = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(getLanguage());
  }, []);

  const dispatch = useAppDispatch();

  const { isLoading } = useGetRoutesQuery();

  const selector = useAppSelector(selectRoute);
  const routes = selector.routes;
  const periods = selector.periods;
  const topics = selector.topics;

  const selectedRoute = selector.selectedRoute;
  const selectedPoint = selector.selectedPoint;

  useEffect(() => {
    // window.location.href = 'revapp://password-reset/test';

    if (!routes) return;

    const urlParams = new URLSearchParams(window.location.search);
    const routeId = urlParams.get('route');

    // window.location.replace(`exp://192.168.0.179:19000/--/share-route/${routeId}`);

    if (routeId && routes.find((r) => r.id == routeId)) {
      dispatch(setSelectedRoute(routes.find((r) => r.id == routeId)));
      // window.location.replace(`exp://192.168.0.179:19000/--/share-route/${routeId}`);
    }
  }, []);

  if (isLoading) return <Loader show={isLoading} />;

  return (
    <>
      <MapWrapper>
        <Map
          routes={routes.filter((r) => !r.isHidden)}
          selectedPoint={selectedPoint}
          onMapClick={() => {
            dispatch(setSelectedPoint());
            dispatch(setSelectedRoute());
          }}
          onMarkerClick={(e, point) => {
            dispatch(setSelectedRoute());
            dispatch(setAudioFile(null));
            dispatch(setSelectedPoint(point));
          }}
          onRouteClick={(e, route) => {
            dispatch(setSelectedPoint());
            dispatch(setAudioFile(null));
            dispatch(setSelectedRoute(route));
          }}
          mapType="routes"
        />
      </MapWrapper>

      <div onClick={() => nativeNavigate('', getLanguage())} className="Toggler Toggler--navigation">
        <Icon name="navPoints" />
      </div>

      <div className="Toggler Toggler--filter" onClick={() => dispatch(toggleSidebarFilter())}>
        <Icon name="filter" />
      </div>

      <div
        className="Toggler Toggler--language"
        onClick={() => nativeNavigate('static-routes', getLanguage() === 'cs' ? 'en' : 'cs')}
      >
        {t('appLanguage')}
      </div>

      <SidebarFilters type="routes" topics={topics} periods={periods} />
      <SidebarPoint selectedPoint={selectedPoint} selectedRoute={selectedRoute} />

      <AudioPlayer />
    </>
  );
};

export default StaticRoutes;
