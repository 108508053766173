import React from 'react';
import cx from 'classnames';

import { ReactComponent as LogoRevoltujiciPraha } from '../../../assets/images/logo-revoltujici-praha.svg';
import { ReactComponent as LogoNadace } from '../../../assets/images/logo-nadace.svg';

interface LoaderProps {
  show?: boolean;
}

export const Loader: React.FC<LoaderProps> = (props) => {
  const { show } = props;

  return (
    <div className={cx('Loader', show && 'Loader--show')}>
      <LogoRevoltujiciPraha />
      <LogoNadace />

      <div className="LoaderBackground" />
    </div>
  );
};
