import React from 'react';
import cx from 'classnames';

// @ts-ignore
import gLightbox from 'glightbox';
import { useAppDispatch } from '../../hooks/redux';

import { Text } from '../Text/Text';
import { Icon } from '../Icon/Icon';
import { t } from '../../i18n/index';
import { setAudioFile } from '../../store/uiSlice';
import { Point, Route } from '../../types';

interface SidebarPointProps {
  selectedPoint?: Point;
  selectedRoute?: Route;
}

export const SidebarPoint: React.FC<SidebarPointProps> = (props) => {
  const { selectedPoint, selectedRoute } = props;
  const dispatch = useAppDispatch();

  const selected = selectedPoint || selectedRoute;
  const isPoint: Point | false = selectedPoint ? selectedPoint : false;
  const isRoute: Route | false = selectedRoute ? selectedRoute : false;

  let selectedTopics: string[] = [];
  if (isPoint) selectedTopics = isPoint?.topics.map((t) => t.name);
  if (isRoute) selectedTopics = isRoute?.topics.map((t) => t.name);

  let routeWithPhotos = false;
  if (isRoute) routeWithPhotos = isRoute.points.find((point) => point.mainPhotoUrl) ? true : false;

  const handleGLightbox = () => {
    if (!selectedPoint) return;

    const { photos = [] } = selectedPoint;
    const options = { type: 'image', alt: '', zoomable: false };

    const elements = photos.map((photo) => {
      return { href: photo.fileUrl, ...options };
    });

    const gallery = gLightbox({ elements, loop: true });
    gallery.open();
  };

  return (
    <div className={cx('Sidebar', 'Sidebar--right', selected && 'Sidebar--opened')}>
      <div className="SidebarInner">
        {selected && (
          <>
            {isPoint && isPoint.mainPhotoUrl && (
              <img className="SidebarPhoto" src={`${isPoint.mainPhotoUrl}`} loading="lazy" alt="" />
            )}
            <Text as="div" size="h1" color="primary" align="center" className="SidebarTitle" children={selected.name} />
            {isPoint && (
              <div className="SidebarActions">
                {isPoint.audioFileUrl && (
                  <div
                    className="SidebarAction SidebarAction--audio"
                    onClick={() => dispatch(setAudioFile(`${isPoint.audioFileUrl}`))}
                  >
                    <div className="SidebarAction__icon-wrapper" children={<Icon name="play" />} />
                    <Text size="small" color="primary" as="span" children={t('Sidebar.audio')} />
                  </div>
                )}

                {isPoint.photos.length > 0 && (
                  <div className="SidebarAction SidebarAction--gallery" onClick={handleGLightbox}>
                    <div className="SidebarAction__icon-wrapper" children={<Icon name="camera" />} />
                    <Text size="small" color="primary" as="span" children={t('Sidebar.photos')} />
                  </div>
                )}
              </div>
            )}

            <div className="SidebarBullets">
              <div className="SidebarBullets__item">
                <Icon name="tag" />
                <Text size="small" as="span">
                  {selectedTopics.join(', ')}
                </Text>
              </div>
              <div className="SidebarBullets__item">
                <Icon name="calendar-check" />
                <Text size="small" as="span">
                  {selected.period.name}
                </Text>
              </div>
            </div>

            <Text as="div" size="base" align="center" className="SidebarDescription">
              <span dangerouslySetInnerHTML={{ __html: selected.description }}></span>
            </Text>

            {isRoute && (
              <>
                {routeWithPhotos && (
                  <div className="SidebarPointsOfInterest">
                    <Icon name="navPoints" />
                    <Text as="span" size="base" weight={500} children={t('Sidebar.pointOfInterests')} />
                  </div>
                )}
                <div className="SidebarGallery">
                  {isRoute.points.map((point) => {
                    if (!point.mainPhotoUrl) return null;

                    return <img key={point.id} className="SidebarGallery__item" src={point.mainPhotoUrl} alt="" />;
                  })}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
