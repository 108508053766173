import React, { useEffect, useRef, useState } from 'react';
import { Point, Route, TSFixMe } from '../../types';

import styles from './styles';
import { getPoints } from './utils/getPoints';
import { getRoute, routeTemps } from './utils/getRoute';

interface MapProps extends google.maps.MapOptions {
  routes?: Route[];
  points?: Point[];
  selectedPoint?: Point;
  onMapClick?: (event: TSFixMe) => void;
  onMarkerClick?: (event: TSFixMe, point: Point) => void;
  onRouteClick?: (event: TSFixMe, route: Route) => void;
  mapType: 'points' | 'routes';
}

export const Map: React.FC<MapProps> = (props) => {
  const { mapType, points = [], routes = [], selectedPoint, onMarkerClick, onMapClick, onRouteClick } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [mapPoints, setMapPoints] = useState<JSX.Element[]>([]);
  const [mapIsFitted, setMapIsFitted] = useState(false);

  // Initialize map
  useEffect(() => {
    if (map || !ref.current) return;

    const instance = new window.google.maps.Map(ref.current, {
      center: { lat: 50.0802225, lng: 14.4235952 },
      zoom: 13,
      styles: styles,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
    });

    if (onMapClick) instance.addListener('click', onMapClick);
    setMap(instance);
  }, [ref, map, onMapClick]);

  useEffect(() => {
    if (!map || mapType === 'routes') return;

    const bounds = new google.maps.LatLngBounds();
    points.map((point) => bounds.extend(point.coordinate));

    if (!mapIsFitted && points.length > 0) map.fitBounds(bounds);
    setMapIsFitted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, mapType, mapIsFitted]);

  useEffect(() => {
    if (!map || mapType === 'routes') return;

    const mapPoints = getPoints({ points, map, selectedPoint, onMarkerClick });

    setMapPoints(mapPoints);
  }, [map, points, selectedPoint, onMarkerClick, mapType]);

  // Routes
  useEffect(() => {
    if (!map || mapType === 'points') return;

    const bounds = new google.maps.LatLngBounds();

    routes.forEach((route) => {
      const { points } = route;
      const polyline = routeTemps.find((r) => r.id === route.id)?.polyline;

      if (polyline) {
        polyline.setMap(map);
        if (onRouteClick) polyline.addListener('click', (e: TSFixMe) => onRouteClick(e, route));
      } else {
        getRoute(points, bounds).then((polyline) => {
          routeTemps.push({ id: route.id, polyline });

          polyline.setMap(map);
          if (onRouteClick) polyline.addListener('click', (e: TSFixMe) => onRouteClick(e, route));
        });
      }
    });

    if (!mapIsFitted && routes.length > 0) map.fitBounds(bounds);
    setMapIsFitted(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, routes, mapType, mapIsFitted]);

  useEffect(() => {
    if (mapType === 'points' || !map) return;

    const mapPoints: JSX.Element[] = [];

    routes.forEach((route) => {
      const { points } = route;

      const pointsM = getPoints({ points, route, map, onMarkerClick, selectedPoint });
      mapPoints.push(...pointsM);
    });

    setMapPoints(mapPoints);
  }, [map, routes, selectedPoint, onMarkerClick, mapType]);

  return (
    <div className="Map" ref={ref}>
      {mapPoints}
    </div>
  );
};
