import React from 'react';
import cx from 'classnames';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  selectPoint,
  resetFilters as resetFiltersPoint,
  setFilters as setFiltersPoint,
  setFilterPeriods as setFilterPeriodsPoint,
  setFilterTopics as setFilterTopicsPoint,
} from '../../store/pointSlice';
import { Period, Topic } from '../../types';
import { Icon } from '../Icon/Icon';
import { Text } from '../Text/Text';
import { selectUi, toggleSidebarFilter } from '../../store/uiSlice';
import { Button } from '../Button/Button';
import {
  selectRoute,
  resetFilters as resetFiltersRoute,
  setFilters as setFiltersRoute,
  setFilterPeriods as setFilterPeriodsRoute,
  setFilterTopics as setFilterTopicsRoute,
} from '../../store/routeSlice';
import { t } from '../../i18n';

interface SidebarFiltersProps {
  type: 'points' | 'routes';
  topics: Topic[];
  periods: Period[];
}

export const SidebarFilters: React.FC<SidebarFiltersProps> = (props) => {
  const { topics, periods, type } = props;

  const dispatch = useAppDispatch();
  const point = useAppSelector(selectPoint);
  const routes = useAppSelector(selectRoute);
  const ui = useAppSelector(selectUi);

  let selectedPeriods: string[] = [];
  let selectedTopics: string[] = [];

  if (type === 'points') {
    selectedPeriods = point.periods.filter((p) => p.isSelected).map((p) => p.name);
    selectedTopics = point.topics.filter((t) => t.isSelected).map((t) => t.name);
  } else if (type === 'routes') {
    selectedPeriods = routes.periods.filter((p) => p.isSelected).map((p) => p.name);
    selectedTopics = routes.topics.filter((t) => t.isSelected).map((t) => t.name);
  }

  const handleSetFilters = (isReset: boolean) => {
    if (type === 'points') {
      dispatch(isReset ? resetFiltersPoint() : setFiltersPoint());
    } else if (type === 'routes') {
      dispatch(isReset ? resetFiltersRoute() : setFiltersRoute());
    }

    dispatch(toggleSidebarFilter());
  };

  const handleSetFilterTopics = (id: string) => {
    if (type === 'points') {
      dispatch(setFilterTopicsPoint(id));
    } else if (type === 'routes') {
      dispatch(setFilterTopicsRoute(id));
    }
  };

  const handleSetFilterPeriods = (id: string) => {
    if (type === 'points') {
      dispatch(setFilterPeriodsPoint(id));
    } else if (type === 'routes') {
      dispatch(setFilterPeriodsRoute(id));
    }
  };

  return (
    <div className={cx('Sidebar', 'Sidebar--left', ui.sidebarFilterShow && 'Sidebar--opened')}>
      <div className="SidebarInner">
        <div className="Filter Filter--opened">
          <div className="FilterHeader">
            <Text as="span" weight={400} className="FilterTitle" children={t('SidebarFilters.themes')} />
            <Text as="span" weight={600} className="FilterSelected">
              {selectedTopics.length > 0 ? selectedTopics.join(', ') : t('SidebarFilters.all')}
            </Text>
          </div>

          <div className="FilterInner">
            {topics.map((topic) => {
              return (
                <div key={topic.id} className="FilterItem" onClick={() => handleSetFilterTopics(topic.id)}>
                  <Text
                    as="span"
                    color={topic.isSelected ? 'primary' : 'base'}
                    weight={topic.isSelected ? 600 : 400}
                    children={topic.name}
                  />

                  {topic.isSelected && <Icon name="checkmark" color="primary" />}
                </div>
              );
            })}
          </div>
        </div>

        <div className="Filter Filter--opened">
          <div className="FilterHeader">
            <Text as="span" weight={400} className="FilterTitle" children={t('SidebarFilters.periods')} />
            <Text as="span" weight={600} className="FilterSelected">
              {selectedPeriods.length > 0 ? selectedPeriods.join(', ') : t('SidebarFilters.all')}
            </Text>
          </div>

          <div className="FilterInner">
            {periods.map((period) => {
              return (
                <div key={period.id} className="FilterItem" onClick={() => handleSetFilterPeriods(period.id)}>
                  <Text
                    as="span"
                    color={period.isSelected ? 'primary' : 'base'}
                    weight={period.isSelected ? 600 : 400}
                    children={period.name}
                  />

                  {period.isSelected && <Icon name="checkmark" color="primary" />}
                </div>
              );
            })}
          </div>
        </div>
        <div className="ButtonGroup">
          <Button title={t('SidebarFilters.apply')} onClick={() => handleSetFilters(false)} />
          <Button title={t('SidebarFilters.reset')} variant="secondary" onClick={() => handleSetFilters(true)} />
        </div>
      </div>
    </div>
  );
};
