import i18n, { TOptions, StringMap } from 'i18next';
import { initReactI18next } from 'react-i18next';

import cs from './i18n.cs';
import en from './i18n.en';

i18n.use(initReactI18next).init({
  lng: 'cs',
  debug: process.env.REACT_APP_ENV === 'dev',
  fallbackLng: 'cs',
  resources: { cs: { translation: { ...cs } }, en: { translation: { ...en } } },
  interpolation: { escapeValue: false },
});

export const t = (
  key: string | TemplateStringsArray | (string | TemplateStringsArray)[],
  namespace?: string,
  options?: string | TOptions<StringMap> | undefined
): string => i18n.t(`${namespace ? `${namespace}.` : ''}${key}`, options);

export const activeLanguage = i18n.language;

export default i18n;
