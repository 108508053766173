import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface uiState {
  navbarHeight: number;
  sidebarFilterShow: boolean;
  currentAudio: string | null;
}

const initialState: uiState = {
  navbarHeight: 0,
  sidebarFilterShow: false,
  currentAudio: null,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setAudioFile(state, action: PayloadAction<uiState['currentAudio']>) {
      state.currentAudio = action.payload;
    },
    setNavbarHeight(state, action: PayloadAction<number>) {
      state.navbarHeight = action.payload;
    },
    toggleSidebarFilter(state) {
      state.sidebarFilterShow = !state.sidebarFilterShow;
    },
  },
});

export const { setNavbarHeight, setAudioFile, toggleSidebarFilter } = uiSlice.actions;
export const selectUi = (state: RootState): uiState => state.ui;
export default uiSlice.reducer;
