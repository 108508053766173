import cx from 'classnames';

export type TextSizes = 'h1' | 'h2' | 'base' | 'small';
export type TextWeights = 400 | 500 | 600 | 700;
export type TextAligns = 'left' | 'center' | 'right';
export type Colors = 'primary' | 'base' | 'grey' | 'success' | 'white';

interface TextProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  as?: keyof Pick<JSX.IntrinsicElements, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div' | 'span'>;
  size?: TextSizes;
  weight?: TextWeights;
  align?: TextAligns;
  color?: Colors;
}

const getElementTag = (size: TextSizes) => {
  if (['h1', 'h2'].includes(size)) {
    return size;
  } else if (['base', 'small'].includes(size)) {
    return 'p';
  } else {
    return 'span';
  }
};

export const Text: React.FC<TextProps> = (props) => {
  const { as, size = 'base', weight, color, align, className, ...rest } = props;

  let Element = getElementTag(size) as keyof JSX.IntrinsicElements;
  if (as) Element = as;

  return (
    <Element
      className={cx(
        'Text',
        `Text--${size}`,
        align && `Text--${align}`,
        weight && `Text--${weight}`,
        color && `color--${color}`,
        className
      )}
      {...rest}
    >
      {props.children}
    </Element>
  );
};
