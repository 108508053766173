import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseUrl } from '../constants';
import { TSFixMe } from '../types';
import { getLanguage } from '../utils/navigate';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}`,

    prepareHeaders: (headers): TSFixMe => {
      // TODO: language
      headers.set('locale', getLanguage());
    },
  }),
  endpoints: () => ({}),
});
