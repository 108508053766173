import cx from 'classnames';

import Sprite from './sprite.svg';
import { IconNames } from './IconNames';
// import { Colors } from '../../constants/Colors';

interface IconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  name: IconNames;
  // color?: Colors;
  className?: string;
}

export const Icon: React.FC<IconProps> = (props) => {
  const { name, color = 'inherit', className, ...rest } = props;

  return (
    <svg className={cx('icon', `icon-${name}`, `color--${color}`, className)} {...rest}>
      <use href={`${Sprite}#${name}`}></use>
    </svg>
  );
};
