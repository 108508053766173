import React, { useEffect, useRef } from 'react';
import { useAppSelector } from '../../hooks/redux';
import { selectUi } from '../../store/uiSlice';

export const AudioPlayer: React.FC = () => {
  const ui = useAppSelector(selectUi);

  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (!audioRef.current || !ui.currentAudio) return;

    audioRef.current.pause();
    audioRef.current.load();
    audioRef.current.play();
  }, [ui.currentAudio]);

  if (!ui.currentAudio) return null;

  return (
    <div className="AudioPlayer">
      <audio controls={true} autoPlay={true} ref={audioRef}>
        <source src={ui.currentAudio ?? ''} type="audio/mpeg" />
      </audio>
    </div>
  );
};
