export default {
  appLanguage: 'EN',

  Sidebar: {
    audio: 'Audio',
    photos: 'Fotky',
    pointOfInterests: 'Body zájmu',
  },

  SidebarFilters: {
    all: 'Vše',
    themes: 'Témata',
    periods: 'Časové období',
    apply: 'Použít filtry',
    reset: 'Resetovat',
  },
};
