import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Period, Point, Route, Topic } from '../types';
import { api } from '../services/apiSlice';
import { RootState } from './index';

export interface RouteState {
  selectedPoint?: Point;
  selectedRoute?: Route;

  routes: Route[];
  periods: Period[];
  topics: Topic[];
}

const initialState: RouteState = {
  selectedPoint: undefined,
  selectedRoute: undefined,
  routes: [],
  periods: [],
  topics: [],
};

const extendedApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getRoutes: builder.query<Route[], void>({
      query: () => `/public/place/route/index`,
      transformResponse: (response: { data: Route[] }) => {
        response.data.forEach((route) => {
          route.points.forEach((point) => {
            point.coordinate.lat = point.coordinate.latitude;
            point.coordinate.lng = point.coordinate.longitude;
          });
        });

        return response.data;
      },
    }),
  }),
});

export const { useGetRoutesQuery } = extendedApi;

const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    setSelectedRoute(state, action: PayloadAction<Route | undefined>) {
      state.selectedRoute = action.payload;
    },
    setSelectedPoint(state, action: PayloadAction<Point | undefined>) {
      state.selectedPoint = action.payload;
    },
    setFilterTopics(state, action: PayloadAction<string | undefined>) {
      const topic = state.topics.find((p) => p.id === action.payload);
      if (topic) topic.isSelected = !topic.isSelected;
    },
    setFilterPeriods(state, action: PayloadAction<string | undefined>) {
      const period = state.periods.find((p) => p.id === action.payload);
      if (period) period.isSelected = !period.isSelected;
    },
    setFilters(state, action: PayloadAction<undefined>) {
      const filteredTopics = state.topics.filter((t) => t.isSelected).map((t) => t.id);
      const filteredPeriods = state.periods.filter((p) => p.isSelected).map((p) => p.id);
      const hasNoFilters = filteredPeriods.length === 0 && filteredTopics.length === 0;

      state.routes.forEach((route) => {
        route.isHidden = false;
        if (hasNoFilters) return;

        let isInPeriods = filteredPeriods.length > 0 ? false : true;
        if (filteredPeriods.length > 0 && filteredPeriods.includes(route.period.id)) {
          isInPeriods = true;
        }

        let isInTopics = filteredTopics.length > 0 ? false : true;
        if (isInPeriods && filteredTopics.length > 0) {
          route.topics.forEach((t) => {
            if (isInTopics) return;
            if (filteredTopics.includes(t.id)) isInTopics = true;
          });
        }

        route.isHidden = !(isInPeriods && isInTopics);
      });
    },
    resetFilters(state) {
      state.topics.forEach((t) => (t.isSelected = false));
      state.periods.forEach((p) => (p.isSelected = false));

      state.routes.forEach((r) => (r.isHidden = false));
    },
  },
  extraReducers(builder) {
    builder.addMatcher(extendedApi.endpoints.getRoutes.matchFulfilled, (state, { payload }) => {
      const periods: Period[] = [];
      const topics: Topic[] = [];

      payload.forEach((route) => {
        if (!periods.find((p) => p.id === route.period.id)) periods.push(route.period);

        route.topics.forEach((topic) => {
          if (!topics.find((t) => t.id === topic.id)) topics.push(topic);
        });
      });

      periods.sort((a, b) => a.name.localeCompare(b.name));
      topics.sort((a, b) => a.name.localeCompare(b.name));

      state.routes = payload;
      state.periods = periods;
      state.topics = topics;
    });
  },
});

export const { setSelectedPoint, setSelectedRoute, setFilterPeriods, setFilterTopics, setFilters, resetFilters } =
  routeSlice.actions;
export const selectRoute = (state: RootState): RouteState => state.route;
export default routeSlice.reducer;
